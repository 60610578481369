// src/App.js

import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import PropTypes from "prop-types";
import Login from "./components/Login/Login";
import { isAuthenticated, setupAuthInterceptor } from "./services/auth";
import Analysis from "./pages/analysis/Analysis";
import GraphDataVisualization from "./pages/GraphPageForTesting";
import OrientationPage from "./pages/orientation/OrientationPage";
import { AppProvider } from "./context/AppContext";
import ProcessMap from "./pages/process_map/ProcessMap";
import { CodeCacheProvider } from "./context/CodeCacheContext";

import "./pages/analysis/FeatureRequest/sharedStyles.css";

// Set up auth interceptor immediately
setupAuthInterceptor();

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  return isAuthenticated() ? (
    children
  ) : (
    <Navigate to={`/login${location.search}`} state={{ from: location }} />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

const AppContent = () => {
  const location = useLocation();
  const isMock = new URLSearchParams(location.search).get("mock") === "true";
  
  return (
    <div className="App">
      <CodeCacheProvider>
        <AppProvider isMock={isMock}>
          <Routes>
            <Route path="/" element={<Analysis />} />
            <Route path="/orientation" element={<OrientationPage />} />
            <Route
              path="/graph-data-visualization"
              element={<GraphDataVisualization />}
            />
            <Route path="/process-map" element={<ProcessMap />} />
          </Routes>
        </AppProvider>
      </CodeCacheProvider>
    </div>
  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <AppContent />
            </PrivateRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
