import React, { useState, useContext, useEffect, useCallback } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import Explorer from "../../components/Explorer/Explorer";
import { AppContext } from "../../context/AppContext";
import { useLocation } from "react-router-dom";
import StatusBox from "../../components/StatusBox/StatusBox";
import DataModelViewer from "../../components/DataModelViewer/DataModelViewer";
import { LogOut, Code } from "lucide-react";
import { logout } from "../../services/auth";
import Dashboard from "../../components/Dashboard/Dashboard";
import RequestPanel from "../../components/RequestPanel/RequestPanel";
import AskAnalysis from "./FeatureRequest/ask_analysis";

const Analysis = () => {
  const {
    setFeatureRequestText,
    currentStatus,
    textStatus,
    handleStartAnalysis,
    enrichedPlanConfirmed,
  } = useContext(AppContext);
  const [, setIsEditing] = useState(false);
  const location = useLocation();
  const [showDataModel, setShowDataModel] = useState(false);
  const [showRightPanel, setShowRightPanel] = useState(false);

  // Wrap shouldShowSolutionPlan in useCallback
  const shouldShowSolutionPlan = useCallback(
    () => currentStatus === "done" && enrichedPlanConfirmed,
    [currentStatus, enrichedPlanConfirmed]
  );

  // Effect now uses the memoized function
  useEffect(() => {
    console.log('Current Status Changed:', currentStatus);
    if (shouldShowSolutionPlan() && !showRightPanel) {
      setShowRightPanel(true);
    }
  }, [currentStatus, enrichedPlanConfirmed, showRightPanel, shouldShowSolutionPlan]);

  // Create featureRequestProps object

  return (
    <div className="min-h-screen bg-gray-900 flex flex-col">
      {/* Top-level header */}
      <div className="bg-gray-800 p-4 flex justify-between items-center border-b border-gray-700">
        <h1 className="text-xl font-bold text-white">Tweezr</h1>
        <div className="flex items-center gap-8">
          <div className="flex items-center gap-2">
            <button
              onClick={() => {
                setShowDataModel(!showDataModel);
                if (!showDataModel) {
                  setTimeout(() => {
                    window.scrollTo({
                      top: document.documentElement.scrollHeight,
                      behavior: "smooth",
                    });
                  }, 100);
                }
              }}
              className={`relative inline-flex h-[24px] w-[40px] items-center rounded-[4px] transition-colors ${
                showDataModel ? "bg-green-500" : "bg-gray-700"
              }`}
            >
              <span
                className={`inline-flex items-center justify-center h-[20px] w-[22px] my-2 transform rounded-lg bg-white transition-transform ${
                  showDataModel
                    ? "translate-x-[1.15rem]"
                    : "translate-x-[0.15rem]"
                }`}
              >
                <Code size={14} className="text-gray-800" />
              </span>
            </button>
          </div>
          <button
            onClick={logout}
            className="flex items-center gap-3 px-6 py-2.5 text-white bg-gray-700/50 hover:bg-gray-600/50 rounded-xl transition-colors font-mono"
          >
            <LogOut className="w-5 h-5" />
            <span>Logout</span>
          </button>
        </div>
      </div>

      {/* Main content area - make it fill available space */}
      <div className="flex-1 flex flex-col h-[calc(100vh-4rem)]">
        <div className="flex-1 p-4 pb-0 h-full">
          <PanelGroup 
            direction="horizontal" 
            className="h-full" 
            id="root"
            style={{ minHeight: showDataModel ? 'calc(100vh - 16rem)' : 'calc(100vh - 8rem)' }}
          >
            {/* Left Panel */}
            <Panel 
              id="left" 
              defaultSize={20} 
              minSize={20}
              maxSize={40}
              collapsible={true}
              style={{ display: shouldShowSolutionPlan() ? "block" : "none" }}
            >
              <div className="h-full flex flex-col">
                <div className="flex-1 bg-gray-800 rounded-lg border border-gray-700 overflow-auto mb-1.5">
                  <RequestPanel
                    onRequestSelect={(request) => {
                      setFeatureRequestText(
                        `${request.title}\n\n${request.description}`
                      );
                      setIsEditing(false);
                    }}
                    onStartAnalysis={handleStartAnalysis}
                  />
                </div>
                <div className="flex-none bg-gray-800 rounded-lg border border-gray-700 overflow-hidden h-[300px]">
                  <Dashboard />
                </div>
              </div>
            </Panel>

            {/* Resize Handle */}
            {shouldShowSolutionPlan() && (
              <PanelResizeHandle className="w-1.5 bg-gray-900 hover:bg-blue-500 transition-colors" />
            )}

            {/* Middle Panel */}
            <Panel
              id="middle"
              defaultSize={shouldShowSolutionPlan() ? 55 : showRightPanel ? 60 : 100}
              minSize={40}
              maxSize={shouldShowSolutionPlan() ? 60 : showRightPanel ? 80 : 100}
            >
              <div className="h-full flex flex-col">
                <div className="flex-1 bg-gray-800 rounded-lg border border-gray-700 overflow-auto mb-1.5">
                  {shouldShowSolutionPlan() ? (
                    <AskAnalysis />
                  ) : (
                    <RequestPanel
                      onRequestSelect={(request) => {
                        setFeatureRequestText(
                          `${request.title}\n\n${request.description}`
                        );
                        setIsEditing(false);
                      }}
                      onStartAnalysis={handleStartAnalysis}
                    />
                  )}
                </div>
                <div className="flex-none">
                  <StatusBox
                    currentStatus={currentStatus}
                    textStatus={textStatus}
                  />
                </div>
              </div>
            </Panel>

            {/* Only show resize handle and right panel when showRightPanel is true */}
            {showRightPanel && (
              <>
                <PanelResizeHandle className="w-1.5 bg-gray-900 hover:bg-blue-500 transition-colors" />

                {/* Right Panel */}
                <Panel
                  id="right"
                  defaultSize={20}
                  minSize={15}
                  maxSize={30}
                >
                  <div className="h-full bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
                    <Explorer selectedFiles={location.state?.selectedFiles || []} />
                  </div>
                </Panel>
              </>
            )}
          </PanelGroup>
        </div>

        {/* Data Model Section */}
        {showDataModel && (
          <div className="p-4 bg-gray-900">
            <div className="bg-gray-800 rounded-lg border border-gray-700 overflow-hidden">
              <div className="max-h-[70vh] overflow-auto">
                <DataModelViewer />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Analysis;
