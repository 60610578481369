import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const EditIcon = ({ onClick }) => (
  <span 
    onClick={onClick}
    className="inline-flex items-center justify-center ml-2 bg-gray-700 hover:bg-gray-600 rounded-md p-1.5 group cursor-pointer"
  >
    <svg 
      className="w-4 h-4 text-gray-300 group-hover:text-white transition-colors" 
      fill="none" 
      stroke="currentColor" 
      viewBox="0 0 24 24" 
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        strokeLinecap="round" 
        strokeLinejoin="round" 
        strokeWidth={2} 
        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" 
      />
    </svg>
  </span>
);

EditIcon.propTypes = {
  onClick: PropTypes.func.isRequired
};

const EnrichedTextEditor = ({ content, currentStatus }) => {
  const [editingParagraphId, setEditingParagraphId] = useState(null);
  const [paragraphContent, setParagraphContent] = useState('');
  const [fullContent, setFullContent] = useState(content);
  const textareaRef = useRef(null);

  useEffect(() => {
    setFullContent(content);
  }, [content]);

  // Keep focus on textarea while editing
  useEffect(() => {
    if (editingParagraphId && textareaRef.current) {
      textareaRef.current.focus();
      // Place cursor at the end
      const length = textareaRef.current.value.length;
      textareaRef.current.setSelectionRange(length, length);
    }
  }, [editingParagraphId, paragraphContent]);

  // Adjust textarea height
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
    }
  }, [paragraphContent]);

  const handleParagraphEdit = (text, id) => {
    if (editingParagraphId && editingParagraphId !== id) return;
    setEditingParagraphId(id);
    setParagraphContent(text);
  };

  const handleParagraphSave = (strongText) => {
    const lines = fullContent.split('\n');
    const updatedLines = lines.map(line => {
      if (line.includes(`**${strongText}**`)) {
        return `**${strongText}** ${paragraphContent}`;
      }
      return line;
    });
    
    const updatedContent = updatedLines.join('\n');
    setFullContent(updatedContent);
    setEditingParagraphId(null);
    setParagraphContent('');
  };

  const handleParagraphCancel = () => {
    setEditingParagraphId(null);
    setParagraphContent('');
  };

  return (
    <div className={currentStatus === "done" ? "mr-[10%]" : "mr-[20%]"}>
      <div className="text-gray-400 text-base mb-2 pl-4">Response:</div>
      <div className="border border-gray-700 rounded-lg p-4 bg-[#111827]">
        <ReactMarkdown
          className="text-[#a6bcd3] text-sm prose prose-invert max-w-none"
          components={{
            p: ({ children }) => {
              const childrenArray = React.Children.toArray(children);
              const isStrongFollowedByText = 
                childrenArray.length >= 2 && 
                React.isValidElement(childrenArray[0]) && 
                childrenArray[0].type === 'strong' &&
                typeof childrenArray[1] === 'string';
              
              if (!isStrongFollowedByText) return (
                <p className="text-[#a6bcd3] mb-4 last:mb-0">{children}</p>
              );

              const strongContent = childrenArray[0];
              const textContent = childrenArray.slice(1).join('');
              const paragraphId = strongContent.props.children + textContent;

              if (editingParagraphId === paragraphId) {
                return (
                  <div className="flex flex-col space-y-2 mb-4">
                    {strongContent}
                    <textarea
                      ref={textareaRef}
                      value={paragraphContent}
                      onChange={(e) => setParagraphContent(e.target.value)}
                      className="w-full bg-gray-900 text-white border border-gray-700 rounded-lg p-2 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none resize-none overflow-hidden"
                    />
                    <div className="flex justify-end gap-2">
                      <button
                        onClick={handleParagraphCancel}
                        className="px-2 py-1 text-sm text-gray-400 hover:text-white transition-colors"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => handleParagraphSave(strongContent.props.children)}
                        className="px-2 py-1 text-sm bg-blue-500 hover:bg-blue-600 text-white rounded transition-colors"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                );
              }

              return (
                <p className="text-[#a6bcd3] mb-4 last:mb-0 editable">
                  {strongContent}
                  <span>
                    <EditIcon onClick={() => handleParagraphEdit(textContent, paragraphId)} />
                  </span>
                  {textContent}
                </p>
              );
            },
            ul: ({ children }) => (
              <ul className="list-disc pl-4 mb-4 last:mb-0">{children}</ul>
            ),
            ol: ({ children }) => (
              <ol className="list-decimal pl-4 mb-4 last:mb-0">{children}</ol>
            ),
            li: ({ children }) => (
              <li className="mb-1 last:mb-0">{children}</li>
            ),
            h1: ({ children }) => (
              <h1 className="text-xl font-semibold text-white mb-4">{children}</h1>
            ),
            h2: ({ children }) => (
              <h2 className="text-lg font-semibold text-white mb-3">{children}</h2>
            ),
            h3: ({ children }) => (
              <h3 className="text-base font-semibold text-white mb-2">{children}</h3>
            ),
            code: ({ children }) => (
              <code className="bg-gray-800 rounded px-1 py-0.5 text-sm font-mono">{children}</code>
            ),
            pre: ({ children }) => (
              <pre className="bg-gray-800 rounded p-3 mb-4 overflow-x-auto">{children}</pre>
            ),
          }}
        >
          {fullContent}
        </ReactMarkdown>
      </div>
    </div>
  );
};

EnrichedTextEditor.propTypes = {
  content: PropTypes.string,
  onSave: PropTypes.func,
  currentStatus: PropTypes.string.isRequired,
};

export default EnrichedTextEditor; 