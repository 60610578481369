import React, { useContext, useMemo } from "react";
import { AppContext } from "../../context/AppContext";
import { FileText, Brain } from "lucide-react";

const Dashboard = () => {
  const { analysisContent, dependencyImpactContent } = useContext(AppContext);

  const metrics = useMemo(() => {
    if (!analysisContent) {
      return {
        tShirtSize: { size: "XS", totalLines: 0 },
        filesCount: "--",
        addedLines: 0,
        removedLines: 0,
      };
    }

    // Calculate lines from code changes
    let addedLines = 0;
    let removedLines = 0;

    analysisContent.changes?.forEach((fileChange) => {
      fileChange.changes?.forEach((change) => {
        // Split into lines and filter out empty lines
        const oldLines =
          change.old_code?.split("\n").filter((line) => line.trim()) || [];
        const newLines =
          change.new_code?.split("\n").filter((line) => line.trim()) || [];

        // For Deletion type or when old_code exists
        if (change.change_type === "Deletion" || oldLines.length > 0) {
          removedLines += oldLines.length;
        }

        // For Addition type or when new_code exists
        if (change.change_type === "Addition" || newLines.length > 0) {
          addedLines += newLines.length;
        }
      });
    });

    const totalLines = addedLines + removedLines;

    // Calculate t-shirt size based on total lines
    const getTShirtSize = (lines) => {
      if (lines <= 9) return "XS";
      if (lines <= 49) return "S";
      if (lines <= 249) return "M";
      if (lines <= 999) return "L";
      return "XL";
    };

    // Calculate total files (including dependencies)
    const mainFiles = analysisContent.changes?.length || 0;
    const dependencyFiles =
      dependencyImpactContent?.dependency_impacts?.length || 0;
    const totalFiles = mainFiles + dependencyFiles;

    return {
      tShirtSize: {
        size: getTShirtSize(totalLines),
        totalLines,
      },
      filesCount: totalFiles,
      addedLines,
      removedLines,
    };
  }, [analysisContent, dependencyImpactContent]);

  return (
    <div className="h-full flex flex-col">
      <div className="p-4">
        <div className="space-y-3">
          <div className="bg-gray-900 rounded-lg p-4 flex items-start gap-3">
            <div className="p-2 bg-blue-500/10 rounded-lg">
              <Brain className="w-5 h-5 text-blue-400" />
            </div>
            <div>
              <div className="text-gray-400 text-sm mb-1">T-Shirt Size</div>
              <div className="flex items-baseline gap-2">
                <div className="text-white text-2xl font-semibold">
                  {metrics.tShirtSize.size}
                </div>
                <div className="text-gray-500 text-sm">
                  {metrics.tShirtSize.totalLines} total lines
                </div>
              </div>
            </div>
          </div>

          <div className="bg-gray-900 rounded-lg p-4 flex items-start gap-3">
            <div className="p-2 bg-green-500/10 rounded-lg">
              <FileText className="w-5 h-5 text-green-400" />
            </div>
            <div>
              <div className="text-gray-400 text-sm mb-1">Impact Scope</div>
              <div className="flex flex-col">
                <div className="text-white text-2xl font-semibold">
                  {metrics.filesCount} files
                </div>
                <div className="text-gray-500 text-sm">
                  <span className="text-green-400">+{metrics.addedLines}</span>
                  <span className="mx-1">/</span>
                  <span className="text-red-400">-{metrics.removedLines}</span>
                  <span className="ml-1">lines</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
