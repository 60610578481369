export const REQUEST_EXAMPLES = [
  {
    title: "ID&Pay: Reduce waiting time in SDD payments to 3 working days",
    description: `I'd like to reduce waiting time for full processing of SDD (SEPA Direct Debit) payments to 3 working days.

For example, a customer Direct monthly deposit set to the 7th of the month. In December, that's a Saturday, so we have the bank collect on the next Monday. Then there are 5 waiting days (because there could be weekends or holidays, in theory), causing the money to land on Friday afternoon. Our investment platform client trades in the morning (with good reason), so the money is invested only on the following Monday!

We should be able to significantly reduce this impact by allowing the waiting days to be specified in workdays (excluding SDD bank holidays). This allows platform to use the exact requirement: 3 workdays' worth of waiting days.`,
  },
  {
    title: "AWS Card Demo: Add joint account functionality",
    description: `I want to add a "joint account" functionality - the ability to associate a single account with multiple customers - as opposed to the existing situation, where each account belongs to a single customer.

Since the GUI currently displays all the customer's details together with the account details on screen, there will not be room to display multiple customers on the same screen. The initial solution should be to make the customer ID field editable, so that a user can switch between customers linked to the same bank account. If a customer ID is not provided by the user (i.e., the field is left blank), then the first customer ID associated with the account ID should be used (similar to current behavior).`,
  },
  {
    title: "AWS Card Demo: Add joint account functionality v2",
    description: `I want to add a "joint account" functionality - the ability to associate a single account with multiple customers - as opposed to the existing situation, where each account belongs to a single customer.

Since the GUI currently displays all the customer's details together with the account details on screen, there will not be room to display multiple customers on the same screen. Therefore, the solution should allow to scroll through different customers for each account, for example by use of a designated F-key.

It is better to avoid introducing new data fields or structures in the code - so for example, it is perfectly okay to only perform the read for the next customer record after the F-key is pressed (as part of the user input processing logic), rather than read all customer records associated with an account into an array in advance.`,
  },
];

export const PROJECT_OPTIONS = [
  {
    value: "cics_example_app",
    label: "CICS Example App",
  },
  {
    value: "batch_example_app",
    label: "Batch Example App",
  },
  {
    value: "db2_example_app",
    label: "DB2 Example App",
  },
];
