export const formatEnrichedFeatureRequest = (enrichedRequest) => {
  if (!enrichedRequest) return "";

  const sections = [
    {
      title: "Features Breakdown",
      content: enrichedRequest.features_breakdown,
      type: "list"
    },
    {
      title: "Technical Guidelines",
      content: enrichedRequest.technical_guidelines,
      type: "list"
    },
    {
      title: "Examples of Functionalities",
      content: enrichedRequest.examples_of_functionalities,
      type: "list"
    },
    {
      title: "Relevant Context",
      content: enrichedRequest.relevant_context,
      type: "list"
    },
    {
      title: "Extracted Constraints",
      content: enrichedRequest.extracted_constraints,
      type: "list"
    }
  ];

  return sections
    .map(section => {
      if (!section.content) return "";
      
      const formattedContent = section.type === "list"
        ? section.content.map(item => `- ${item}`).join("\n")
        : section.content;

      return `\n\n**${section.title}:**\n\n${formattedContent}`;
    })
    .join("")
    .trim();
};
