import React, { useContext, useMemo, useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { ChevronRight } from "lucide-react";
import { REQUEST_EXAMPLES } from "./constants";
import { formatEnrichedFeatureRequest } from "./utils";
import { AppContext, ProcessingStatus } from "../../context/AppContext";
import EnrichedTextEditor from "./components/EnrichedTextEditor";
import { useSearchParams } from "react-router-dom";

const RequestOption = ({
  id,
  title,
  description,
  onClick,
  isExpanded,
  onExpand,
  onTryItOut,
}) => {
  // Get namespace from title (we'll update constants.js later to include namespaces)
  const getNamespaceLabel = (title) => {
    if (title.startsWith("ID&Pay:"))
      return { text: "ID&Pay", color: "blue", key: "idp" };
    if (title.startsWith("AWS Card Demo:"))
      return {
        text: "AWS Card Demo",
        color: "green",
        key: "aws_mainframe_modernization_carddemo",
      };
    return { text: "CICS", color: "purple", key: "aws_cics" }; // default
  };

  const { text: namespaceText, color: namespaceColor } =
    getNamespaceLabel(title);

  // Remove namespace prefix from title if it exists
  const cleanTitle = title.replace(/^(ID&Pay:|AWS Card Demo:)\s*/, "");

  return (
    <div className="relative">
      <button
        onClick={() => onExpand(id)}
        onDoubleClick={() => onClick()}
        className="w-full text-left px-4 py-2 bg-[#2c3c4e] hover:bg-[#2c3c4e]/80 rounded-lg transition-colors group mx-auto block"
      >
        <div className="flex items-start justify-between">
          <div className="flex-1">
            <div className="flex items-center gap-2 mb-1">
              <h3 className="text-gray-300 text-base">{cleanTitle}</h3>
              <span
                className={`px-2 py-0.5 text-xs rounded bg-${namespaceColor}-500/20 text-${namespaceColor}-300 border border-${namespaceColor}-500/30`}
              >
                {namespaceText}
              </span>
            </div>
            {!isExpanded && (
              <p className="text-[#a6bcd3] text-sm line-clamp-2">
                {description.split("\n")[0]}
              </p>
            )}
          </div>
          <ChevronRight
            className={`w-5 h-5 text-white group-hover:text-white/80 transition-all ml-4 flex-shrink-0 transform ${
              isExpanded ? "rotate-90" : ""
            }`}
          />
        </div>
      </button>

      <div
        className={`
          mt-[4px] bg-[#2c3c4e] rounded-lg
          transition-all duration-200 ease-in-out
          overflow-hidden px-4
          ${
            isExpanded
              ? "py-3 max-h-[500px] opacity-100"
              : "max-h-0 opacity-0 pointer-events-none"
          }
        `}
      >
        <p className="text-[#a6bcd3] text-sm whitespace-pre-wrap">
          {description}
        </p>
        <button
          onClick={() => {
            onTryItOut(description, title);
            onClick();
          }}
          className="mt-4 px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors"
        >
          Try it out
        </button>
      </div>
    </div>
  );
};

const LoadingDots = () => (
  <div className="flex items-center gap-1">
    <div className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-[pulse_1s_ease-in-out_infinite]"></div>
    <div className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-[pulse_1s_ease-in-out_infinite_0.2s]"></div>
    <div className="w-1.5 h-1.5 bg-gray-400 rounded-full animate-[pulse_1s_ease-in-out_infinite_0.4s]"></div>
  </div>
);

const RequestPanel = ({ onRequestSelect }) => {
  const {
    sendMessage,
    enrichedFeatureRequest,
    NAMESPACES,
    featureRequestText,
    currentStatus,
    enrichedPlanConfirmed,
    selectedNamespace,
    setSelectedNamespace,
    setEnrichedPlanConfirmed,
  } = useContext(AppContext);

  const [customRequest, setCustomRequest] = useState(featureRequestText || "");
  const [expandedId, setExpandedId] = useState(null);
  const [showExamples, setShowExamples] = useState(true);
  const [submittedRequest, setSubmittedRequest] = useState(
    featureRequestText || ""
  );

  // Get mock state from URL parameters
  const [searchParams] = useSearchParams();
  const [isMockEnabled] = useState(searchParams.get("mock") === "true");
  const [showDependencyImpact] = useState(true);
  const [showSolutionConfig] = useState(true);
  const [showMiniGraph] = useState(true);
  const [showSuggestEdits] = useState(true);

  const messagesEndRef = useRef(null);
  const prevEnrichedFeatureRequestRef = useRef(enrichedFeatureRequest);
  const [isLoading, setIsLoading] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setCustomRequest(featureRequestText);

    if (currentStatus === "done") {
      // Show examples if there's no submitted request, regardless of customRequest
      setShowExamples(false);
      setSubmittedRequest(featureRequestText);
    }
  }, [featureRequestText, currentStatus]);

  // Scroll only when enrichedFeatureRequest is added
  useEffect(() => {
    const wasEmpty = !prevEnrichedFeatureRequestRef.current;
    const isNewResponse = enrichedFeatureRequest && wasEmpty;

    if (currentStatus !== "done" && isNewResponse) {
      scrollToBottom();
    }

    prevEnrichedFeatureRequestRef.current = enrichedFeatureRequest;
  }, [enrichedFeatureRequest, currentStatus]);

  // Initialize with cics_example_app instead of first option
  // useEffect(() => {
  //   if (!selectedNamespace) {
  //     setSelectedNamespace("aws_cics"); // cics_example_app namespace
  //   }
  // }, [selectedNamespace, setSelectedNamespace]);

  const handleExpand = (id) => {
    setExpandedId(expandedId === id ? null : id);
  };


  const handleTryItOut = (text) => {
    // Update namespace based on the example
    // Rest of the handleTryItOut logic
    setIsLoading(true);

    onRequestSelect({
      title: "Custom Request",
      description: text,
    });

    setShowExamples(false);
    setSubmittedRequest(text);

    const params = {
      mock: isMockEnabled,
      dependency_impact: showDependencyImpact,
      solution_config: showSolutionConfig,
      mini_graph: showMiniGraph,
      suggest_edits: showSuggestEdits,
    };

    sendMessage({
      feature_request: text,
      params: params,
      namespace: selectedNamespace,
    });
  };

  useEffect(() => {
    if (enrichedFeatureRequest) {
      setIsLoading(false);
    }
  }, [enrichedFeatureRequest]);

  const formattedEnrichedFeatureRequest = useMemo(() => {
    if (!enrichedFeatureRequest) return "";
    return formatEnrichedFeatureRequest(enrichedFeatureRequest);
  }, [enrichedFeatureRequest]);

  const handleSaveEdit = (editedContent) => {
    sendMessage({
      feature_request: editedContent,
      params: {
        mock: isMockEnabled,
        dependency_impact: showDependencyImpact,
        solution_config: showSolutionConfig,
        mini_graph: showMiniGraph,
        suggest_edits: showSuggestEdits,
      },
    });
  };

  const handleContinueAnalyzing = () => {
    setEnrichedPlanConfirmed(true);
  };

  // Check if we're in dependency analysis phase
  const showSolutionPlanButton =
    currentStatus === ProcessingStatus.START_DEPENDENCY_IMPACT ||
    currentStatus === ProcessingStatus.DONE;
  console.log(selectedNamespace, "selectedNamespace");
  return (
    <div className="flex flex-col h-full">
      {/* Header */}
      <div className="flex-none bg-gray-800/50 border-b border-gray-700">
        <div className="p-6 flex justify-between items-center">
          <h2 className="text-xl font-bold text-white">Feature Request</h2>
          <select
            className="w-64 bg-gray-900 border border-gray-700 rounded-lg p-1.5 text-sm text-white focus:outline-none focus:border-blue-500"
            value={selectedNamespace}
            onChange={(e) => setSelectedNamespace(e.target.value)}
          >
            {Object.entries(NAMESPACES).map(([key, value]) => (
              <option key={key} value={value}>
                {key}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Messages container - make it fill available space */}
      <div className="flex-1 overflow-y-auto p-4">
        <div
          className={`mx-auto ${
            currentStatus === "done" && enrichedPlanConfirmed
              ? "w-full"
              : "w-[70%]"
          }`}
        >
          {showExamples ? (
            <>
              <h2 className="text-xl font-semibold text-white mb-6">
                Example feature requests
              </h2>
              <div className="w-full flex flex-col gap-1">
                {REQUEST_EXAMPLES.map((request, index) => (
                  <RequestOption
                    key={index}
                    id={index}
                    title={request.title}
                    description={request.description}
                    onClick={() => onRequestSelect(request)}
                    isExpanded={expandedId === index}
                    onExpand={handleExpand}
                    onTryItOut={(text) => handleTryItOut(text, request.title)}
                  />
                ))}
              </div>
            </>
          ) : (
            <div className="flex flex-col min-h-0">
              <div className="space-y-4">
                {/* First message with dynamic margin */}
                {submittedRequest && (
                  <div
                    className={`${
                      currentStatus === "done" && enrichedPlanConfirmed
                        ? "ml-[10%]"
                        : "ml-[20%]"
                    } bg-[#2c3c4e] rounded-lg p-4`}
                  >
                    <p className="text-[#a6bcd3] text-sm whitespace-pre-wrap">
                      {submittedRequest}
                    </p>
                  </div>
                )}

                {/* Response message with dynamic margin */}
                {enrichedFeatureRequest && (
                  <div className="space-y-4">
                    <EnrichedTextEditor
                      content={formattedEnrichedFeatureRequest}
                      onSave={handleSaveEdit}
                      currentStatus={currentStatus}
                    />
                    {!enrichedPlanConfirmed && showSolutionPlanButton && (
                      <div className="flex justify-end">
                        <button
                          onClick={handleContinueAnalyzing}
                          className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors"
                        >
                          Solution Plan
                        </button>
                      </div>
                    )}
                  </div>
                )}
                <div ref={messagesEndRef} />
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Bottom section - Loading indicator and form */}
      {(isLoading || !submittedRequest) && (
        <div className="flex-none">
          {/* Loading indicator */}
          {isLoading && (
            <div
              className={`mx-auto px-4 ${
                currentStatus === "done" && enrichedPlanConfirmed
                  ? "w-full"
                  : "w-[70%]"
              }`}
            >
              <div className="flex items-center gap-2 bg-[#1e2936] rounded-lg py-2 px-4">
                <div className="flex items-center gap-2">
                  <LoadingDots />
                  <span className="text-gray-400 text-sm">
                    Analyzing request...
                  </span>
                </div>
              </div>
            </div>
          )}

          {/* Form section - only show if no request has been submitted yet */}
          {!submittedRequest && (
            <div className="p-4 bg-gray-800/50">
              <div
                className={`mx-auto ${
                  currentStatus === "done" && enrichedPlanConfirmed
                    ? "w-full"
                    : "w-[70%]"
                }`}
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleTryItOut(customRequest, "Custom Request");
                  }}
                >
                  <div className="space-y-4">
                    <div className="relative">
                      <textarea
                        value={customRequest}
                        onChange={(e) => setCustomRequest(e.target.value)}
                        placeholder="Type a message"
                        className="w-full h-44 bg-gray-900 text-white border border-gray-700 rounded-lg p-3 pb-14 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 outline-none resize-none"
                      />
                      <button
                        type="submit"
                        disabled={!customRequest.trim() || isLoading}
                        className="absolute bottom-4 right-2 bg-blue-500 hover:bg-blue-600 disabled:bg-gray-700 disabled:cursor-not-allowed text-white p-2 rounded-lg transition-colors"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="opacity-90"
                        >
                          <path d="M12 19V5M5 12l7-7 7 7" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

RequestOption.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onExpand: PropTypes.func.isRequired,
  onTryItOut: PropTypes.func.isRequired,
};

RequestPanel.propTypes = {
  onRequestSelect: PropTypes.func.isRequired,
};

export default RequestPanel;
