import React, {
  createContext,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import dataModelService from "../services/dataModelService";
import PropTypes from "prop-types";

export const ProcessingStatus = {
  START_FEATURE_ENRICHMENT: "start_feature_enrichment",
  START_FEATURE_REQUEST_ENRICHMENT: "start_feature_request_enrichment",
  START_RELEVANT_FILES: "start_relevant_files",
  START_SOLUTION_PLAN: "start_solution_plan",
  START_SUGGEST_EDITS: "start_suggest_edits",
  START_DEPENDENCY_IMPACT: "start_dependency_impact",
  START_MINI_GRAPH: "start_mini_graph",
  DONE: "done",
};

export const ContentType = {
  STATUS: "status",
  TEXT_STATUS: "text_status",
  FEATURE_REQUEST_ENRICHMENT: "feature_request_enrichment",
  RELEVANT_FILES: "relevant_files",
  SUGGEST_EDITS: "suggest_edits",
  DEPENDENCY_IMPACT: "dependency_impact",
  SOLUTION_PLAN: "solution_plan",
  MINI_GRAPH: "mini_graph",
  ERROR: "error",
};

export const NAMESPACES = {
  aws_mainframe_modernization_carddemo: "aws_mainframe_modernization_carddemo",
  cics_example_app: "cics",
  idp: "idp",
  cobol_programming_course: "cobol_programming_course",
  X_COBOL_Files: "X_COBOL_Files",
};

// Get default namespace from allowed namespaces

export const AppContext = createContext(null);

export const AppProvider = ({ children, isMock }) => {
  const [featureRequestText, setFeatureRequestText] = useState("");
  const [enrichedFeatureRequest, setEnrichedFeatureRequest] = useState(null);
  const [enrichedPlanConfirmed, setEnrichedPlanConfirmed] = useState(false);
  const [wsConnected, setWsConnected] = useState(false);
  const [expandedCards, setExpandedCards] = useState([]);
  const [mainPanelSizes, setMainPanelSizes] = useState([70, 30]);
  const wsRef = useRef(null);
  const retryCountRef = useRef(0);
  const maxRetries = 5;

  // State for different content types
  const [relevantFilesContent, setRelevantFilesContent] = useState([]);
  const [analysisContent, setAnalysisContent] = useState(null);
  const [dependencyImpactContent, setDependencyImpactContent] = useState({
    dependency_impacts: [],
  });
  const [currentStatus, setCurrentStatus] = useState("idle");
  const [textStatus, setTextStatus] = useState(null);
  const [accumulatedFiles, setAccumulatedFiles] = useState({});
  const [likelyFiles, setLikelyFiles] = useState([]);
  const [selectedNamespace, setSelectedNamespace] = useState(
    NAMESPACES.aws_mainframe_modernization_carddemo
  );

  const [solutionPlan, setSolutionPlan] = useState(null);
  const [solutionOverviewItemsInfo, setSolutionOverviewItemsInfo] =
    useState(null);
  const [miniGraphContent, setMiniGraphContent] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  // New unified data model state
  const [fileDataModel, setFileDataModel] = useState({});

  // Modified update function to prevent empty placeholders
  const updateFileData = (filePath, updater) => {
    console.log("💾 Updating file data:", {
      filePath,
      currentData: fileDataModel[filePath],
      updater,
    });

    setFileDataModel((prev) => {
      const updated = {
        ...prev,
        [filePath]: updater(prev[filePath] || {}),
      };
      console.log("📊 Updated file data model:", updated[filePath]);
      return updated;
    });
  };

  useEffect(() => {
    const connectWebSocket = () => {
      if (retryCountRef.current >= maxRetries) {
        console.error("Max retries reached for WebSocket.");
        return;
      }

      const protocol = window.location.protocol === "https:" ? "wss" : "ws";
      const token = localStorage.getItem("auth_token");

      // Ensure token is available
      if (!token) {
        console.error("Missing token for WebSocket connection");
        return;
      }

      // Build query parameters
      const queryParams = new URLSearchParams();
      if (isMock) queryParams.append("mock", "true");
      const queryString = queryParams.toString();

      const websocketUrl =
        window.location.protocol === "https:"
          ? `${protocol}://${window.location.host}/ws${
              queryString ? "?" + queryString : ""
            }`
          : `ws://localhost/ws${queryString ? "?" + queryString : ""}`;

      console.log("Connecting to WebSocket URL:", websocketUrl);

      // Create WebSocket connection with auth protocol
      const ws = new WebSocket(websocketUrl, [`token.${token}`]);

      ws.onopen = () => {
        console.log("WebSocket connected");
        setWsConnected(true);
        retryCountRef.current = 0;

        // Send empty initial message
        ws.send(
          JSON.stringify({
            message: {},
          })
        );
      };

      wsRef.current = ws;

      wsRef.current.onmessage = handleWebSocketMessage;

      wsRef.current.onerror = (error) => {
        console.error("WebSocket error:", error);
        setWsConnected(false);
        // Protocol rejection is handled as an error
        if (error.target?.readyState === WebSocket.CLOSED) {
          console.error(
            "WebSocket connection rejected - likely due to authentication failure"
          );
        }
      };

      wsRef.current.onclose = (event) => {
        console.log("WebSocket closed:", event);
        setWsConnected(false);

        // Don't retry if it was an authentication failure
        if (event.code === 1002 || event.code === 1008) {
          console.error(
            "WebSocket closed due to authentication failure - not retrying"
          );
          return;
        }

        if (event.code !== 1000) {
          retryCountRef.current += 1;
          setTimeout(connectWebSocket, 1000);
        }
      };
    };

    if (!wsRef.current || wsRef.current.readyState !== WebSocket.OPEN) {
      connectWebSocket();
    }

    return () => {
      if (wsRef.current) {
        wsRef.current.close(1000, "Component unmounting");
      }
    };
  }, [isMock]);

  const sendMessage = useCallback(
    (message) => {
      console.log("Sending message:", { message, selectedNamespace });

      if (!wsRef.current || wsRef.current.readyState !== WebSocket.OPEN) {
        console.error("WebSocket is not connected");
        return;
      }

      try {
        wsRef.current.send(
          JSON.stringify({
            message: {
              feature_request: message.feature_request,
              namespace: message.namespace || selectedNamespace,
              params: message.params,
            },
          })
        );
      } catch (error) {
        console.error("Error sending message:", error);
      }
    },
    [selectedNamespace]
  );

  const toggleCard = (cardId) => {
    setExpandedCards((prev) =>
      prev.includes(cardId)
        ? prev.filter((id) => id !== cardId)
        : [...prev, cardId]
    );
  };

  const getCode = async (filePath) => {
    const url = `/get_code/${encodeURIComponent(filePath)}${
      isMock ? "?mock=true" : ""
    }`;
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.content;
  };

  useEffect(() => {
    return () => {
      // Don't clear accumulated files on unmount
      // This allows the files to persist between pages
    };
  }, []);

  const clearAccumulatedFiles = () => {
    // Only clear when explicitly called, not on component unmount
    setAccumulatedFiles({});
  };

  const handleWebSocketMessage = (event) => {
    const response = JSON.parse(event.data);
    console.log("🔄 WebSocket Message received:", response);
    console.log("🔄 Content type:", response.content_type);
    console.log("🔄 Current content types:", ContentType);

    switch (response.content_type) {
      case ContentType.STATUS: {
        console.log("📊 Setting status:", response.content);
        setCurrentStatus(response.content);
        setTextStatus(null); // Clear text status when main status changes
        break;
      }

      case ContentType.TEXT_STATUS: {
        console.log("📝 Setting text status:", response.content);
        setTextStatus(response.content);
        break;
      }

      case ContentType.RELEVANT_FILES: {
        const likelyFiles = dataModelService.processRelevantFiles(
          response.content
        );
        const newFiles = Array.isArray(response.content.Files)
          ? response.content.Files
          : [response.content.Files];

        setFileDataModel(dataModelService.getFileDataModel());

        // Accumulate files with deduplication
        setRelevantFilesContent((prevFiles) => {
          const existingPaths = new Set(prevFiles.map((f) => f.file_path));
          const uniqueNewFiles = newFiles.filter(
            (f) => !existingPaths.has(f.file_path)
          );
          return [...prevFiles, ...uniqueNewFiles];
        });

        // Update likely files similarly
        if (likelyFiles.length > 0) {
          setLikelyFiles((prev) => {
            const existingPaths = new Set(prev.map((f) => f.file_path));
            const uniqueNewFiles = likelyFiles.filter(
              (f) => !existingPaths.has(f.file_path)
            );
            return [...prev, ...uniqueNewFiles];
          });
        }
        break;
      }

      case ContentType.SOLUTION_PLAN: {
        const transformedPlan = dataModelService.processSolutionPlan(
          response.content
        );
        setFileDataModel(dataModelService.getFileDataModel());
        setSolutionOverviewItemsInfo(response.content.solution_overview);
        setSolutionPlan(response.content.solution_overview);
        const analysisResult = {
          summary: response.content.solution_overview
            .map((item) => item.explanation)
            .join("\n\n"),
          changes: response.content.solution_overview.flatMap((item) =>
            item.files.map((file) => ({
              file_path: file.filepath,
              changes: file.changes || [],
            }))
          ),
        };
        setAnalysisContent(analysisResult);
        break;
      }

      case ContentType.SUGGEST_EDITS: {
        const analysisResult = dataModelService.processSuggestedEdits(
          response.content
        );
        setFileDataModel(dataModelService.getFileDataModel());
        setAnalysisContent(analysisResult);
        break;
      }

      case ContentType.DEPENDENCY_IMPACT: {
        const impactContent = dataModelService.processDependencyImpact(
          response.content
        );
        setFileDataModel(dataModelService.getFileDataModel());
        setDependencyImpactContent((prev) => ({
          dependency_impacts: [...prev.dependency_impacts, impactContent],
        }));
        break;
      }

      case ContentType.MINI_GRAPH: {
        try {
          console.log("📊 Received mini_graph data:", response.content);
          const graphData = dataModelService.processMiniGraph(response.content);
          console.log("📊 Processed graph data:", graphData);
          if (graphData) {
            setMiniGraphContent(graphData);
            console.log("📊 Updated miniGraphContent:", graphData);
          }
        } catch (error) {
          console.error("❌ Error processing mini graph:", error);
        }
        break;
      }

      case ContentType.ERROR:
        setErrorMessage(response.content.message);
        setTextStatus(null); // Clear text status on error
        break;

      case ContentType.FEATURE_REQUEST_ENRICHMENT: {
        console.log("🔍 Setting enriched feature request:", response.content);
        console.log(
          "🔍 (Truncated) FEATURE_REQUEST_ENRICHMENT response:",
          JSON.stringify(response.content).slice(0, 500)
        );
        // response.content is now an object with keys:
        // {
        //   original_feature_request: string
        //   features_breakdown: string[]
        //   technical_guidelines: string[]
        //   examples_of_functionalities: string[]
        //   relevant_context: string[]
        //   extracted_constraints: string[]
        // }
        setEnrichedFeatureRequest(response.content);
        break;
      }

      default:
        console.warn("Unknown content type:", response.content_type);
    }
  };

  const handleStartAnalysis = async () => {
    try {
      setCurrentStatus("analyzing");
      // ... analysis logic ...
      setCurrentStatus("done");
    } catch (error) {
      console.error("Analysis failed:", error);
      setCurrentStatus("error");
    }
  };

  return (
    <AppContext.Provider
      value={{
        featureRequestText,
        setFeatureRequestText,
        enrichedFeatureRequest,
        enrichedPlanConfirmed,
        setEnrichedPlanConfirmed,
        wsConnected,
        expandedCards,
        toggleCard,
        mainPanelSizes,
        setMainPanelSizes,
        sendMessage,
        currentStatus,
        setCurrentStatus,
        textStatus,
        relevantFilesContent,
        analysisContent,
        dependencyImpactContent,
        accumulatedFiles,
        clearAccumulatedFiles,
        likelyFiles,
        selectedNamespace,
        setSelectedNamespace,
        NAMESPACES,
        solutionPlan,
        solutionOverviewItemsInfo,
        miniGraphContent,
        errorMessage,
        getCode,
        fileDataModel,
        updateFileData,
        isMock,
        handleStartAnalysis,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
  isMock: PropTypes.bool,
};

AppProvider.defaultProps = {
  isMock: false,
};
