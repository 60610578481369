import React, {
  useContext,
  useMemo,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import { Panel, PanelGroup } from "react-resizable-panels";
import {
  ChevronDown,
  ChevronRight,
  ChevronUp,
} from "lucide-react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import PropTypes from "prop-types";

import CodeRenderer from "./CodeRenderer";
import { AppContext, ProcessingStatus } from "../../../context/AppContext";
import LoadingAnimation from "./LoadingAnimation";
import { getFileIcon } from "../../../shared/fileIcons";
import {
  formatMarkdownText,
  getDistinctiveFileName,
  getLastTwoFolders,
} from "../../../utils/outputPreprocess";
import { useSearchParams } from "react-router-dom";

// Add FileNameBox component before it's used
const FileNameBox = ({ fileName }) => (
  <span className="inline-flex items-center px-2 py-0.5 rounded bg-blue-500/10 border border-blue-300/20 text-blue-300 font-mono text-sm">
    <span className="mr-1.5">{getFileIcon(fileName, 14)}</span>
    {getDistinctiveFileName(fileName)}
  </span>
);

// Add PropTypes for FileNameBox
FileNameBox.propTypes = {
  fileName: PropTypes.string.isRequired,
};

// Add FileBox component after FileNameBox is defined
const FileBox = ({ children }) => {
  if (!children) return null;
  const fileName = Array.isArray(children) ? children.join("") : children;
  return <FileNameBox fileName={fileName} />;
};

FileBox.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

// Add PropTypes for FileNameBox
FileNameBox.propTypes = {
  fileName: PropTypes.string.isRequired,
};

// Summary of Changes Component
const SummaryBox = ({
  type,
  content,
  findRelevantDependencies,
  onFileRender,
}) => {
  const { fileDataModel } = useContext(AppContext);
  const [searchParams] = useSearchParams();
  const showSolutionConfig = searchParams.get("solution_config") === "true";

  const replaceFilePathsWithNames = (text) => {
    if (!text) return text;
    const regex = /<file>([^<]+)<\/file>|<method>([^<]+)<\/method>|<table>([^<]+)<\/table>|<variable>([^<]+)<\/variable>/g;
    return text.replace(regex, (match, file, method, table, variable) => {
      if (file) {
        if (file.includes("code_samples/")) {
          const fileName = fileDataModel[file]?.file_name;
          return fileName ? `\`${fileName}\`` : `\`${file}\``;
        }
        return `\`${file}\``;
      }
      if (method) return `<method>${method}</method>`;
      if (table) return `<table-custom>${table}</table-custom>`;
      if (variable) return `\`${variable}\``;
      return match;
    });
  };

  const renderMarkdown = (text) => {
    const commonElementStyle = "inline-flex items-center bg-[#1B2332] text-[#C6CCD7] px-2 rounded-lg border border-[#3B82F6] font-mono gap-2";

    return (
      <ReactMarkdown
        className="text-gray-300 prose prose-invert max-w-none"
        rehypePlugins={[rehypeRaw]}
        components={{
          p: ({ children }) => (
            <div className="relative flex items-start mb-4">
              <span className="absolute left-0 text-gray-500 text-3xl leading-6">•</span>
              <span className="flex-1 pl-6">{children}</span>
            </div>
          ),
          code: ({ children }) => (
            <span className={commonElementStyle}>
              {getFileIcon(children, 14)}
              {children}
            </span>
          ),
          strong: ({ children }) => (
            <span className="font-bold text-white">{children}</span>
          ),
          method: ({ children }) => (
            <span className={commonElementStyle}>
              {getFileIcon("method", 14)}
              {children}
            </span>
          ),
          "table-custom": ({ children }) => (
            <span className={commonElementStyle}>
              {getFileIcon("table", 14)}
              {children}
            </span>
          ),
          file: ({ children }) => (
            <span className={commonElementStyle}>
              {getFileIcon("file", 14)}
              {children}
            </span>
          ),
          variable: ({ children }) => (
            <span className={commonElementStyle}>
              {getFileIcon("variable", 14)}
              {children}
            </span>
          ),
        }}
      >
        {replaceFilePathsWithNames(text)}
      </ReactMarkdown>
    );
  };

  const renderContent = () => {
    switch (type) {
      case "solution_plan":
        return (
          <div>
            {content?.map((item, index) => {
              return (
                <div key={index} className="mb-2 solution-plan-item px-[21px] py-3 hover:bg-[#6B7280]/50 transition-colors rounded-lg">
                  {/* Main explanation */}
                  <div className="explanation">
                    {renderMarkdown(item.explanation)}
                  </div>

                  {/* Category and Assessment badges */}
                  {showSolutionConfig && (
                    <div className="flex gap-3 ml-6 mt-2 text-sm">
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full bg-blue-500/10 text-blue-400 border border-blue-500/20">
                        {item.change_category.type}
                      </span>
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full bg-green-500/10 text-green-400 border border-green-500/20">
                        Self Assessment: {item.self_assessment}
                      </span>
                    </div>
                  )}

                  {/* Render FileCards for each file in the item */}
                  {item.files?.map(fileObj => {
                    onFileRender?.(fileObj.filepath);
                    return (
                      <div key={fileObj.filepath} className="mt-4 ml-6">
                        <FileCard
                          file={{
                            file_path: fileObj.filepath,
                            changes: fileObj.changes
                          }}
                          findRelevantDependencies={findRelevantDependencies}
                          showAssessment={false}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      case "summary":
        return renderMarkdown(formatMarkdownText(content));
      default:
        return null;
    }
  };

  return (
    <div className="bg-gray-800 border border-gray-700 rounded-lg p-6 mb-6">
      <h2 className="text-xl font-bold mb-6">
        {type === "solution_plan" ? "Solution Plan" : "Summary of Changes"}
      </h2>
      {renderContent()}
    </div>
  );
};

// Add PropTypes for SummaryBox
SummaryBox.propTypes = {
  type: PropTypes.oneOf(["solution_plan", "summary"]).isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      solution_plan: PropTypes.string,
      detailed_breakdown: PropTypes.string,
    }),
  ]).isRequired,
  filesWithChanges: PropTypes.arrayOf(
    PropTypes.shape({
      file_path: PropTypes.string.isRequired,
      changes: PropTypes.array,
      description: PropTypes.string,
    })
  ),
  findRelevantDependencies: PropTypes.func,
  onFileRender: PropTypes.func,
};

// CodeBlock component to display code changes
const CodeBlock = ({ filePath, changes, showHeader = true }) => {
  if (!changes || !Array.isArray(changes)) return null;

  // Combine all changes into a single array
  const combinedCodeChanges = changes.map(change => ({
    old_code: change.old_code,
    new_code: change.new_code,
    start_line: change.start_line,
    end_line: change.end_line
  }));

  return (
    <div className="bg-[#0D1117] rounded-lg overflow-hidden border border-gray-700">
      {showHeader && (
        <div className="px-4 py-3 bg-[#161B22] border-b border-gray-700">
          <h4 className="text-blue-300 font-semibold">Changes</h4>
        </div>
      )}
      <div className="p-0">
        <CodeRenderer
          filePath={filePath}
          showHeader={false}
          change={{
            code_changes: combinedCodeChanges
          }}
        />
      </div>
    </div>
  );
};

// Add PropTypes for CodeBlock
CodeBlock.propTypes = {
  filePath: PropTypes.string.isRequired,
  changes: PropTypes.arrayOf(
    PropTypes.shape({
      old_code: PropTypes.string,
      new_code: PropTypes.string,
      start_line: PropTypes.number,
      end_line: PropTypes.number,
      change_type: PropTypes.string
    })
  ),
  showHeader: PropTypes.bool
};

// Update the FileNameDisplay component
const FileNameDisplay = ({ filePath, showAssessment = true }) => {
  const { fileDataModel } = useContext(AppContext);
  const distinctiveName = getDistinctiveFileName(filePath, fileDataModel);
  const pathParts = filePath.split("/");
  const lastTwoFolders = getLastTwoFolders(filePath);

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-2">
        <span className="font-mono font-bold text-xl text-white">
          {distinctiveName}
        </span>
        {lastTwoFolders && showAssessment && (
          <span className="px-2 py-1 text-xs bg-gray-600 text-blue-300 rounded">
            /{lastTwoFolders}
          </span>
        )}
      </div>
      <span className="text-gray-400 text-xs">/{pathParts.join("/")}</span>
    </div>
  );
};

// Add PropTypes for FileNameDisplay
FileNameDisplay.propTypes = {
  filePath: PropTypes.string.isRequired,
  showAssessment: PropTypes.bool,
};

// Dependency Card Component
const DependencyCard = ({ dependency }) => {
  const { expandedCards, toggleCard } = useContext(AppContext);
  const isExpanded = expandedCards.includes(dependency.file_path);

  console.log("DependencyCard rendering:", {
    filePath: dependency.file_path,
    isExpanded,
    description: dependency.description,
    codeChanges: dependency.code_changes,
  });

  return (
    <div className="dependency-card mb-4 bg-gray-700 rounded-lg overflow-hidden border border-gray-600">
      <div
        className="p-4 bg-gray-600 cursor-pointer hover:bg-gray-500"
        onClick={() => toggleCard(dependency.file_path)}
      >
        <div className="flex items-center space-x-2">
          <span className="text-white">
            {isExpanded ? (
              <ChevronDown size={20} />
            ) : (
              <ChevronRight size={20} />
            )}
          </span>
          <FileNameDisplay filePath={dependency.file_path} />
        </div>
      </div>

      {isExpanded && (
        <div className="p-4">
          <div className="bg-gray-800 p-4 rounded-lg mb-4">
            <h5 className="text-lg font-bold mb-3 text-blue-300">
              Impact Description:
            </h5>
            <ReactMarkdown className="text-white prose prose-invert max-w-none">
              {dependency.description}
            </ReactMarkdown>
          </div>

          <div className="bg-gray-800 p-4 rounded-lg">
            <CodeBlock
              filePath={dependency.file_path}
              codeChanges={dependency.code_changes}
            />
          </div>
        </div>
      )}
    </div>
  );
};

// Add PropTypes for DependencyCard
DependencyCard.propTypes = {
  dependency: PropTypes.shape({
    file_path: PropTypes.string.isRequired,
    description: PropTypes.string,
    code_changes: PropTypes.object,
  }).isRequired,
};

// Add this new component with simplified design
const RelevanceAssessment = ({ assessment }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (!assessment) return null;

  return (
    <div
      className="mt-2 cursor-pointer group"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className="flex items-center gap-2 text-gray-300">
        <span className="text-gray-500 group-hover:text-gray-300 transition-colors"></span>
        <div className={`text-sm ${isExpanded ? "" : "line-clamp-3"}`}>
          <ReactMarkdown className="prose prose-invert max-w-none">
            {assessment}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
};

// Add PropTypes for RelevanceAssessment
RelevanceAssessment.propTypes = {
  assessment: PropTypes.string,
};

// Add a new collapsible section component
const CollapsibleSection = ({
  title,
  isOpen: defaultIsOpen = false,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  return (
    <div className="mb-6">
      <div
        className="flex items-center cursor-pointer mb-4"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-white mr-2">
          {isOpen ? <ChevronDown size={20} /> : <ChevronRight size={20} />}
        </span>
        <h2 className="text-xl font-bold">{title}</h2>
      </div>

      {isOpen && children}
    </div>
  );
};

// Add PropTypes for CollapsibleSection
CollapsibleSection.propTypes = {
  title: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  children: PropTypes.node,
};

// Add a collapsible text component
const CollapsibleText = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="relative">
      <div
        className={`text-gray-300 text-sm ${!isExpanded ? "line-clamp-3" : ""}`}
      >
        {text}
      </div>
      {text.length > 150 && ( // Only show button for long text
        <button
          className="text-blue-400 text-sm mt-1 hover:text-blue-300 flex items-center"
          onClick={(e) => {
            e.stopPropagation(); // Prevent card expansion
            setIsExpanded(!isExpanded);
          }}
        >
          {isExpanded ? (
            <>
              <ChevronUp size={16} className="mr-1" />
              Show less
            </>
          ) : (
            <>
              <ChevronDown size={16} className="mr-1" />
              Show more
            </>
          )}
        </button>
      )}
    </div>
  );
};

// Add PropTypes for CollapsibleText
CollapsibleText.propTypes = {
  text: PropTypes.string.isRequired,
};

// Helper function to format file path


// Tooltip component
const Tooltip = ({ text, children }) => {
  const [show, setShow] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const triggerRef = useRef(null);

  const updateTooltipPosition = () => {
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      setPosition({
        x: rect.left,
        y: rect.bottom + 5,
      });
    }
  };

  useEffect(() => {
    if (show) {
      updateTooltipPosition();
      window.addEventListener("scroll", updateTooltipPosition);
      window.addEventListener("resize", updateTooltipPosition);
    }
    return () => {
      window.removeEventListener("scroll", updateTooltipPosition);
      window.removeEventListener("resize", updateTooltipPosition);
    };
  }, [show]);

  return (
    <span
      ref={triggerRef}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
      className="relative"
    >
      {children}
      {show && (
        <div
          className="fixed z-50 bg-gray-900 text-xs px-2 py-1 rounded text-gray-300 border border-gray-700/50 whitespace-nowrap pointer-events-none"
          style={{ left: position.x, top: position.y }}
        >
          {text}
        </div>
      )}
    </span>
  );
};

// Add PropTypes for Tooltip
Tooltip.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

// FileCard component to display file information and changes
const FileCard = ({
  file,
  findRelevantDependencies,
  isPreview = false,
  showAssessment = true,
}) => {
  const { expandedCards, toggleCard } = useContext(AppContext);
  const isExpanded = !isPreview && expandedCards.includes(file.file_path);

  // Get dependencies if needed
  const fileDependencies = useMemo(() => {
    if (isPreview) return [];
    return findRelevantDependencies?.(file.file_path) || [];
  }, [isPreview, findRelevantDependencies, file.file_path]);

  // Calculate line changes
  const lineChanges = useMemo(() => {
    if (!file.changes) return { additions: 0, deletions: 0 };
    
    return file.changes.reduce((acc, change) => {
      // Split into lines and filter out empty lines
      const oldLines = change.old_code?.split('\n').filter(line => line.trim()) || [];
      const newLines = change.new_code?.split('\n').filter(line => line.trim()) || [];
      
      // For Deletion type or when old_code exists
      if (change.change_type === 'Deletion' || oldLines.length > 0) {
        acc.deletions += oldLines.length;
      }
      
      // For Addition type or when new_code exists
      if (change.change_type === 'Addition' || newLines.length > 0) {
        acc.additions += newLines.length;
      }
      
      return acc;
    }, { additions: 0, deletions: 0 });
  }, [file.changes]);

  return (
    <div className="bg-[#0D1117] rounded-[8px] border border-[#30363D] overflow-hidden">
      <div className="flex items-center justify-between gap-2 px-3 py-1.5 bg-[#1F2937] border-b border-[#30363D]">
        <div className="flex items-center gap-2">
          <button
            onClick={() => toggleCard(file.file_path)}
            className="p-1 hover:bg-[#30363D] rounded-sm transition-colors"
          >
            {isExpanded ? (
              <ChevronDown size={16} className="text-gray-400" />
            ) : (
              <ChevronRight size={16} className="text-gray-400" />
            )}
          </button>
          <Tooltip text={file.file_path}>
            <span className="inline-flex items-center px-2 py-1 rounded bg-[#1B2332] border border-[#3B82F6] text-[#C6CCD7] font-mono gap-1.5">
              {getFileIcon(file.file_path, 14)}
              {(() => {
                const lastTwoFoldersPath = getLastTwoFolders(file.file_path);
                const fileName = file.file_path.split('/').pop();
                return lastTwoFoldersPath ? `${lastTwoFoldersPath}/${fileName}` : fileName;
              })()}
            </span>
          </Tooltip>
          <div className="flex items-center gap-2 px-2 py-1 rounded bg-[#1B2332] border border-[#30363D]">
            <div className="flex items-center gap-1 text-xs font-mono">
              {lineChanges.additions > 0 && (
                <span className="text-green-500">+{lineChanges.additions}</span>
              )}
              {lineChanges.deletions > 0 && (
                <span className="text-red-500">-{lineChanges.deletions}</span>
              )}
            </div>
            <div className="flex gap-0.5">
              {[...Array(4)].map((_, i) => {
                const totalChanges = lineChanges.additions + lineChanges.deletions;
                const greenSquares = totalChanges ? Math.round((lineChanges.additions / totalChanges) * 4) : 0;
                return (
                  <div
                    key={i}
                    className={`w-2 h-2 rounded-sm ${
                      i < greenSquares ? 'bg-green-500/50' : 'bg-red-500/50'
                    }`}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {fileDependencies.length > 0 && (
          <span className="text-xs px-2 py-0.5 rounded-[4px] bg-[#388BFD1A] text-[#58A6FF]">
            {fileDependencies.length} Dependencies
          </span>
        )}
      </div>

      {isExpanded && (
        <div className="p-4">
          <PanelGroup direction="horizontal">
            <Panel defaultSize={70} minSize={40}>
              <div className="pl-4">
                {file.changes && (
                  <CodeBlock
                    filePath={file.file_path}
                    changes={file.changes}
                    showHeader={showAssessment}
                  />
                )}
                {fileDependencies.length > 0 && (
                  <div className="mt-4">
                    <h3 className="text-lg font-semibold mb-2 text-gray-300">
                      Dependencies
                    </h3>
                    {fileDependencies.map((dep) => (
                      <FileCard
                        key={dep.file_path}
                        file={dep}
                        isPreview={true}
                      />
                    ))}
                  </div>
                )}
              </div>
            </Panel>
          </PanelGroup>
        </div>
      )}
    </div>
  );
};

// Update PropTypes
FileCard.propTypes = {
  file: PropTypes.shape({
    file_path: PropTypes.string.isRequired,
    changes: PropTypes.array
  }).isRequired,
  findRelevantDependencies: PropTypes.func,
  isPreview: PropTypes.bool,
  showAssessment: PropTypes.bool
};

// Add new Error Message component
const ErrorMessage = ({ message }) => (
  <div className="h-full flex items-center justify-center">
    <div className="bg-red-900/20 border border-red-700 rounded-lg p-6 max-w-2xl">
      <h3 className="text-red-400 text-lg font-semibold mb-2">Error</h3>
      <p className="text-gray-300">{message}</p>
    </div>
  </div>
);

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

// Main Analysis Component
const AnalysisView = () => {
  const {
    analysisContent,
    likelyFiles,
    currentStatus,
    dependencyImpactContent,
    solutionPlan,
    errorMessage,
  } = useContext(AppContext);
  
  // Track rendered files
  const [renderedFiles] = useState(new Set());

  // Add file tracking to SummaryBox
  const trackRenderedFile = useCallback(
    (filePath) => {
      renderedFiles.add(filePath);
    },
    [renderedFiles]
  );

  const organizedFiles = useMemo(() => {
    if (!analysisContent?.changes) {
      return {
        filesWithChanges: [],
        otherRelevantFiles: [],
      };
    }

    // Create a Map to deduplicate files by path
    const fileMap = new Map();

    // Process changes first
    analysisContent.changes.forEach((change) => {
      if (!fileMap.has(change.file_path)) {
        const relevantFileData = likelyFiles?.find(
          (file) => file.file_path === change.file_path
        );
        fileMap.set(change.file_path, {
          file_path: change.file_path,
          changes: change.changes,
          relevancy: relevantFileData?.relevancy,
          assessment_of_relevance: relevantFileData?.assessment_of_relevance,
          description: change.description,
        });
      }
    });

    // Get other relevant files that don't have changes
    const filesWithChangePaths = new Set(fileMap.keys());
    const otherFiles =
      likelyFiles?.filter(
        (file) => !filesWithChangePaths.has(file.file_path)
      ) || [];

    return {
      filesWithChanges: Array.from(fileMap.values()),
      otherRelevantFiles: otherFiles,
    };
  }, [analysisContent?.changes, likelyFiles]);

  // Filter out already rendered files
  const remainingFiles = useMemo(() => {
    return organizedFiles.filesWithChanges.filter(
      (file) => !renderedFiles.has(file.file_path)
    );
  }, [organizedFiles.filesWithChanges, renderedFiles]);

  const findRelevantDependencies = useCallback(
    (filePath) => {
      if (!dependencyImpactContent?.dependency_impacts) return [];
      return dependencyImpactContent.dependency_impacts
        .filter((impact) => impact.key_change_file_path === filePath)
        .map((impact) => ({
          file_path: impact.file_path,
          description: impact.description,
          code_changes: impact.code_changes,
        }));
    },
    [dependencyImpactContent]
  );

  // Show error message if present
  if (errorMessage) {
    return <ErrorMessage message={errorMessage} />;
  }

  return (
    <div className="h-full">
      {currentStatus &&
        currentStatus !== ProcessingStatus.DONE &&
        !solutionPlan && (
          <div className="h-full flex items-center justify-center bg-gray-800 rounded-lg border border-gray-700 p-6">
            <LoadingAnimation />
          </div>
        )}

      {/* Solution Plan - Show first when available */}
      {solutionPlan && (
        <SummaryBox
          filesWithChanges={organizedFiles.filesWithChanges}
          type="solution_plan"
          content={solutionPlan}
          findRelevantDependencies={findRelevantDependencies}
          onFileRender={trackRenderedFile}
        />
      )}
    </div>
  );
};

// Wrapped Export
const AskAnalysis = () => {
  return <AnalysisView />;
};

export default AskAnalysis;