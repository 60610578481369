import React, { useState, useEffect, useContext, useMemo } from "react";
import { AppContext } from "../../../context/AppContext";
import { CodeCacheContext } from "../../../context/CodeCacheContext";
import PropTypes from "prop-types";
import { getFileIcon } from "../../../shared/fileIcons";

import "./CodeRenderer.css";

const CodeRenderer = ({ change, filePath, showHeader = true }) => {
  const [fullCode, setFullCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { getCode } = useContext(AppContext);
  const { getCachedCode, setCachedCode } = useContext(CodeCacheContext);
  const [expandedSections, setExpandedSections] = useState(new Set());
  const CONTEXT_SIZE = 3;

  const ExpandButton = ({ onClick, direction, numLines }) => (
    <tr className="h-6 hover:bg-gray-800/50 border-y border-gray-700/50">
      <td colSpan={3} className="text-center">
        <button
          onClick={onClick}
          className="inline-flex items-center gap-2 text-blue-400 hover:text-blue-300 text-xs py-1"
        >
          <span className="text-gray-500">
            {direction === "up" ? "↑" : direction === "down" ? "↓" : "↕"}
          </span>
          <span>
            {direction === "up"
              ? `Show ${numLines} lines above`
              : direction === "down"
              ? `Show ${numLines} lines below`
              : `Show ${numLines} lines in between`}
          </span>
        </button>
      </td>
    </tr>
  );

  ExpandButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    direction: PropTypes.oneOf(["up", "down", "between"]).isRequired,
    numLines: PropTypes.number.isRequired,
  };

  const handleExpand = (changeIndex, direction) => {
    setExpandedSections((prev) => {
      const next = new Set(prev);
      next.add(`${changeIndex}-${direction}`);

      // If expanding down, also mark the "up" section of next change as expanded
      if (direction === "down") {
        next.add(`${changeIndex + 1}-up`);
      }
      // If expanding up, also mark the "down" section of previous change as expanded
      if (direction === "up") {
        next.add(`${changeIndex - 1}-down`);
      }

      return next;
    });
  };

  const handleExpandBetween = (changeIndex, gapStart, gapEnd) => {
    setExpandedSections((prev) => {
      const next = new Set(prev);
      next.add(`${changeIndex}-between-${gapStart}-${gapEnd}`);
      return next;
    });
  };

  const processedChanges = useMemo(() => {
    if (!change?.code_changes || !fullCode) return [];

    const lines = [];
    const codeChanges = Array.isArray(change.code_changes)
      ? change.code_changes
      : [change.code_changes];
    const allLines = fullCode.split("\n");

    codeChanges.forEach((codeChange, changeIndex) => {
      const nextChange = codeChanges[changeIndex + 1];
      const prevChange = codeChanges[changeIndex - 1];

      // Calculate the visible range for this change, including context
      const visibleStart = Math.max(0, codeChange.start_line - CONTEXT_SIZE);
      const visibleEnd = Math.min(
        allLines.length - 1,
        codeChange.end_line + CONTEXT_SIZE
      );

      // Calculate gaps relative to previous change
      if (prevChange) {
        const prevVisibleEnd = prevChange.end_line + CONTEXT_SIZE;
        const currentVisibleStart = codeChange.start_line - CONTEXT_SIZE;
        const gapSize = currentVisibleStart - prevVisibleEnd - 1;

        if (gapSize > 0) {
          // Show the gap expansion button
          const gapKey = `${changeIndex}-between-${prevVisibleEnd + 1}-${
            currentVisibleStart - 1
          }`;

          if (!expandedSections.has(gapKey)) {
            lines.push({
              type: "expand-between",
              changeIndex: changeIndex - 1,
              gapStart: prevVisibleEnd + 1,
              gapEnd: currentVisibleStart - 1,
              numLines: gapSize,
            });
          } else {
            // Show the expanded gap lines
            for (let i = prevVisibleEnd + 1; i < currentVisibleStart; i++) {
              lines.push({
                type: "context",
                lineNumber: i + 1,
                oldLineNumber: i + 1,
                content: allLines[i],
              });
            }
          }
        }
      } else {
        // First change - handle lines before it
        if (visibleStart > 0) {
          const hasExpandedStart = expandedSections.has(`${changeIndex}-up`);
          if (!hasExpandedStart) {
            lines.push({
              type: "expand-up",
              changeIndex,
              numLines: visibleStart,
            });
          } else {
            // Show expanded lines at the start
            for (let i = 0; i < visibleStart; i++) {
              lines.push({
                type: "context",
                lineNumber: i + 1,
                oldLineNumber: i + 1,
                content: allLines[i],
              });
            }
          }
        }
      }

      // Add context lines before the change
      for (
        let i = Math.max(
          prevChange ? prevChange.end_line + 1 : visibleStart,
          visibleStart
        );
        i < codeChange.start_line;
        i++
      ) {
        lines.push({
          type: "context",
          lineNumber: i + 1,
          oldLineNumber: i + 1,
          content: allLines[i],
        });
      }

      // Add the changed lines
      const oldLines =
        codeChange.old_code?.split("\n").filter((line) => line.trim()) || [];
      const newLines =
        codeChange.new_code?.split("\n").filter((line) => line.trim()) || [];

      // Handle deletions first - show all deleted lines
      if (codeChange.change_type === "Deletion" || oldLines.length > 0) {
        oldLines.forEach((line, i) => {
          lines.push({
            type: "deletion",
            lineNumber: null,
            oldLineNumber: codeChange.start_line + i,
            content: line,
          });
        });
      }

      // Handle additions - show all added lines
      if (codeChange.change_type === "Addition" || newLines.length > 0) {
        newLines.forEach((line, i) => {
          lines.push({
            type: "addition",
            lineNumber: codeChange.start_line + i,
            oldLineNumber: null,
            content: line,
          });
        });
      }

      // Unchanged lines within the change
      oldLines.forEach((line, i) => {
        if (newLines[i] && line === newLines[i]) {
          lines.push({
            type: "context",
            lineNumber: codeChange.start_line + i,
            oldLineNumber: codeChange.start_line + i,
            content: line,
          });
        }
      });

      // Add context lines after the change
      for (let i = codeChange.end_line + 1; i <= visibleEnd; i++) {
        if (i < allLines.length) {
          lines.push({
            type: "context",
            lineNumber: i + 1,
            oldLineNumber: i + 1,
            content: allLines[i],
          });
        }
      }

      // Last change - handle lines after it
      if (!nextChange && visibleEnd < allLines.length - 1) {
        const hasExpandedEnd = expandedSections.has(`${changeIndex}-down`);
        if (!hasExpandedEnd) {
          lines.push({
            type: "expand-down",
            changeIndex,
            numLines: allLines.length - visibleEnd - 1,
          });
        } else {
          // Show expanded lines at the end
          for (let i = visibleEnd + 1; i < allLines.length; i++) {
            lines.push({
              type: "context",
              lineNumber: i + 1,
              oldLineNumber: i + 1,
              content: allLines[i],
            });
          }
        }
      }
    });

    return lines;
  }, [change, fullCode, expandedSections]);

  useEffect(() => {
    const fetchCode = async () => {
      setIsLoading(true);
      try {
        const cleanPath = filePath.replace(/^\/+/, "");
        const cachedContent = getCachedCode(cleanPath);

        if (cachedContent) {
          setFullCode(cachedContent);
        } else {
          const codeContent = await getCode(cleanPath);
          if (codeContent) {
            setCachedCode(cleanPath, codeContent);
            setFullCode(codeContent);
          }
        }
      } catch (error) {
        console.error("Error fetching code:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (filePath) {
      fetchCode();
    }
  }, [filePath, getCode, getCachedCode, setCachedCode]);

  if (isLoading) {
    return <div className="text-gray-400 p-4">Loading code...</div>;
  }

  return (
    <div className="bg-[#0D1117] rounded-lg overflow-hidden border border-gray-700">
      {showHeader && (
        <div className="flex items-center px-4 py-2 bg-[#161B22] border-b border-gray-700">
          <div className="flex items-center gap-2">
            {getFileIcon(filePath)}
            <span className="text-gray-400 font-mono text-sm">{filePath}</span>
          </div>
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="w-full border-spacing-0">
          <tbody>
            {processedChanges.map((line, idx) =>
              line.type === "expand-up" ? (
                <ExpandButton
                  key={`expand-up-${idx}`}
                  direction="up"
                  numLines={line.numLines}
                  onClick={() => handleExpand(line.changeIndex, "up")}
                />
              ) : line.type === "expand-down" ? (
                <ExpandButton
                  key={`expand-down-${idx}`}
                  direction="down"
                  numLines={line.numLines}
                  onClick={() => handleExpand(line.changeIndex, "down")}
                />
              ) : line.type === "expand-between" ? (
                <ExpandButton
                  key={`expand-between-${idx}`}
                  direction="between"
                  numLines={line.numLines}
                  onClick={() =>
                    handleExpandBetween(
                      line.changeIndex,
                      line.gapStart,
                      line.gapEnd
                    )
                  }
                />
              ) : (
                <tr
                  key={idx}
                  className={`
                    hover:bg-gray-800/50
                    ${line.type === "addition" ? "bg-green-500/10" : ""}
                    ${line.type === "deletion" ? "bg-red-500/10" : ""}
                  `}
                >
                  <td className="select-none pl-4 pr-2 py-0.5 text-right text-gray-500 border-r border-gray-700 w-12 font-mono text-xs">
                    {line.oldLineNumber}
                  </td>

                  <td className="select-none pl-2 pr-2 py-0.5 text-right text-gray-500 border-r border-gray-700 w-12 font-mono text-xs">
                    {line.type === "addition" && (
                      <span className="text-white mr-1">+</span>
                    )}
                    {line.type === "deletion" && (
                      <span className="text-white mr-1">-</span>
                    )}
                    {line.lineNumber}
                  </td>

                  <td
                    className={`pl-4 pr-4 py-0.5 whitespace-pre font-mono text-sm ${
                      line.type === "addition"
                        ? "text-green-300"
                        : line.type === "deletion"
                        ? "text-red-300"
                        : "text-gray-300"
                    }`}
                  >
                    {line.content}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

CodeRenderer.propTypes = {
  change: PropTypes.shape({
    change_description: PropTypes.string,
    code_changes: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    highlighted_code: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    ]),
  }),
  filePath: PropTypes.string.isRequired,
  showHeader: PropTypes.bool,
};

export default CodeRenderer;
