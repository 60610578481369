// Data model service to handle processing and storage
class DataModelService {
  constructor() {
    this.fileDataModel = {};
  }

  // Helper to extract file name from path
  getFileNameFromPath(filePath) {
    console.log('getFileNameFromPath input:', filePath, typeof filePath);
    
    // Handle null/undefined
    if (!filePath) {
        console.warn('Received null/undefined filePath');
        return '';
    }
    
    // Convert to string if it's not already
    const pathString = String(filePath);
    
    try {
        return pathString.split('/').pop();
    } catch (error) {
        console.error('Error processing filePath:', error);
        return '';
    }
  }

  // Process relevant files content
  processRelevantFiles(content) {
    if (!content.Files) return;

    const files = Array.isArray(content.Files)
      ? content.Files
      : [content.Files];

    files.forEach((file) => {
      if (file.file_path && file.relevancy) {
        this.updateFileData(file.file_path, {
          file_metadata: {
            status: file.status,
            lastUpdated: new Date().toISOString(),
          },
          relevant_files: {
            relevancy: file.relevancy,
            textual_representation: file.textual_representation,
            assessment_of_relevance: file.assessment_of_relevance,
          },
        });
      }
    });

    return files.filter((file) => file.relevancy === "likely");
  }

  // Process solution plan content
  processSolutionPlan(content) {
    const { solution_overview } = content;
    console.log("Processing solution overview:", solution_overview);

    if (!Array.isArray(solution_overview)) {
        console.warn("Invalid solution_overview format:", solution_overview);
        return null;
    }

    // Process each solution item
    solution_overview.forEach((item) => {
        // Process each file in the solution item
        item.files.forEach((fileObj) => {
            const filePath = fileObj.filepath;
            if (!filePath) {
                console.warn("Invalid file path in solution plan");
                return;
            }

            this.updateFileData(filePath, {
                solution_plan: {
                    highlights: solution_overview
                        .filter((plan) => 
                            plan.files.some(f => f.filepath === filePath)
                        )
                        .map((plan) => ({
                            highlight: plan.explanation,
                            explanation: plan.explanation,
                            files: plan.files,
                            change_category: plan.change_category,
                            self_assessment: plan.self_assessment,
                            changes: plan.files.find(f => f.filepath === filePath)?.changes || []
                        })),
                },
            });
        });
    });

    // Format the solution plan for the UI
    return {
        solution_plan: solution_overview.map((item) => {
            // Create a summary string that includes the explanation and affected files
            const summary = `${item.explanation}\n\nFiles affected:\n${
                item.files.map(f => `- ${f.filepath}`).join('\n')
            }\n\nChange category: ${item.change_category.type} - ${item.change_category.reasoning}\n` +
            `Self assessment score: ${item.self_assessment}`;
            
            return summary;
        }).join('\n\n---\n\n')
    };
  }

  // Process suggested edits content
  processSuggestedEdits(content) {
    console.log("🔍 Processing suggested edits:", content);
    if (!content.code_highlights) return null;

    const changesByFile = new Map();

    content.code_highlights.forEach((highlight) => {
      console.log("📝 Processing highlight:", {
        filePath: highlight.file_path,
        codeChanges: highlight.code_changes,
      });

      if (highlight.file_path) {
        // Update the file data model
        this.updateFileData(highlight.file_path, {
          suggest_edits: [
            {
              change_description: highlight.change_description,
              highlighted_code: highlight.highlighted_code,
              code_changes: highlight.code_changes,
            },
          ],
        });

        // Group changes for UI
        const existingChanges = changesByFile.get(highlight.file_path) || {
          file_path: highlight.file_path,
          changes: [],
        };

        existingChanges.changes.push({
          change_description: highlight.change_description,
          explanation: highlight.change_description,
          highlighted_code: highlight.highlighted_code,
          code_changes: highlight.code_changes,
        });

        changesByFile.set(highlight.file_path, existingChanges);
      }
    });

    const result = {
      code_highlights: content.code_highlights,
      changes: Array.from(changesByFile.values()),
    };

    console.log("✨ Processed suggested edits result:", result);
    return result;
  }

  // Process dependency impact content
  processDependencyImpact(content) {
    /**
     * 'content' is expected to match the new schema:
     * {
     *   file_path: string,
     *   key_change_file_path: string,
     *   has_impact: boolean,
     *   description: string,
     *   technical_implications: string
     * }
     */
    if (!content?.file_path) {
      console.warn("[DataModelService] Missing file_path in dependency impact:", content);
      return null;
    }

    // For safety:
    const { file_path } = content;
    console.log("[DataModelService] Processing dependency impact for:", {
      file_path,
      key_change_file_path: content.key_change_file_path,
      has_impact: content.has_impact,
      hasDescription: !!content.description,
      hasTechnicalImplications: !!content.technical_implications
    });

    // Store it in the fileDataModel under 'dependency_impact'
    this.updateFileData(file_path, {
      dependency_impact: {
        has_impact: content.has_impact ?? !!(content.description || content.technical_implications),
        description: content.description || "",
        technical_implications: content.technical_implications || "",
        key_change_file_path: content.key_change_file_path || "",
      },
    });

    console.log("[DataModelService] Updated dependency impact state for", file_path, ":", 
                this.fileDataModel[file_path]?.dependency_impact);

    return content;
  }

  // Update file data with automatic file name extraction
  updateFileData(filePath, newData) {
    this.fileDataModel[filePath] = {
      ...this.fileDataModel[filePath],
      file_name: this.getFileNameFromPath(filePath), // Add file name
      ...newData,
    };

    // Merge arrays instead of replacing them
    ["suggest_edits", "changes"].forEach((arrayKey) => {
      if (newData[arrayKey]) {
        this.fileDataModel[filePath][arrayKey] = [
          ...(this.fileDataModel[filePath][arrayKey] || []),
          ...newData[arrayKey],
        ];
      }
    });

    return this.fileDataModel[filePath];
  }

  // Get the entire file data model
  getFileDataModel() {
    return this.fileDataModel;
  }

  // Process mini graph content
  processMiniGraph(content) {
    console.log("🔍 Processing mini graph input:", content);

    if (!content?.nodes || !content?.edges) {
      console.warn("⚠️ Invalid mini graph content:", content);
      return null;
    }

    try {
      // Validate nodes and edges
      const validNodes = content.nodes.filter((node) => {
        const isValid = node && node.name;
        if (!isValid) console.warn("⚠️ Invalid node:", node);
        return isValid;
      });

      const validEdges = content.edges.filter((edge) => {
        const isValid = edge && edge.node1?.name && edge.node2?.name;
        if (!isValid) console.warn("⚠️ Invalid edge:", edge);
        return isValid;
      });

      console.log("✅ Valid nodes:", validNodes.length);
      console.log("✅ Valid edges:", validEdges.length);

      // Create maps to store node and edge references
      const nodeReferences = new Map();
      const edgeReferences = new Map();

      // Process nodes
      validNodes.forEach((node) => {
        nodeReferences.set(node.name, {
          id: node.name,
          name: node.name,
          type: node.type || "unknown",
          references: [],
        });
      });

      // Process edges
      validEdges.forEach((edge) => {
        const sourceNode = edge.node1.name;
        const targetNode = edge.node2.name;

        // Add edge references to source node
        if (nodeReferences.has(sourceNode)) {
          nodeReferences.get(sourceNode).references.push({
            type: edge.type || "unknown",
            direction: "outgoing",
            connectedTo: targetNode,
          });
        }

        // Add edge references to target node
        if (nodeReferences.has(targetNode)) {
          nodeReferences.get(targetNode).references.push({
            type: edge.type || "unknown",
            direction: "incoming",
            connectedTo: sourceNode,
          });
        }

        // Store edge reference with guaranteed unique IDs
        const edgeKey = `${sourceNode}-${targetNode}`;
        edgeReferences.set(edgeKey, {
          id: edgeKey,
          source: sourceNode,
          target: targetNode,
          type: edge.type || "unknown",
        });
      });

      // Update file data model
      nodeReferences.forEach((nodeData, filePath) => {
        if (filePath && filePath.startsWith("code_samples/")) {
          this.updateFileData(filePath, {
            mini_graph: {
              node_type: nodeData.type,
              references: nodeData.references,
            },
          });
        }
      });

      const processedData = {
        nodes: Array.from(nodeReferences.values()),
        edges: Array.from(edgeReferences.values()),
        findNodeReferences: (nodeName) =>
          nodeReferences.get(nodeName)?.references || [],
        findConnectedNodes: (nodeName) => {
          const node = nodeReferences.get(nodeName);
          return node ? node.references.map((ref) => ref.connectedTo) : [];
        },
      };

      console.log("✅ Final processed data:", processedData);
      return processedData;
    } catch (error) {
      console.error("❌ Error in processMiniGraph:", error);
      return null;
    }
  }
}

export default new DataModelService();
